
























import {PageContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/PageContext';
import RequestEmployeesModule from '../../../MainMenu/Module/RequestEmployeesModule/RequestEmployeesModule.vue';

export default {
    name: 'HeaderRequestEmployeesModule',
    components: {RequestEmployeesModule},
    props: {
        context: null as PageContext
    }
};
