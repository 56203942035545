var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.awards
    ? _c(
        "div",
        { staticClass: "awards" },
        _vm._l(_vm.awards, function (award) {
          return _c("div", { staticClass: "awards__image" }, [
            _c("img", {
              attrs: {
                src: "/assets/awards/" + award,
                width: "120px",
                height: "154px;",
                alt: "Führender Dienstleister für Zeitarbeit 2021",
              },
            }),
          ])
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }