


























export default {
    name: 'Awards',
    data()
    {
        return {
            awards: []
        };
    }
};
